import axios from 'axios'
 
//配置全局数据请求类型
axios.defaults.headers['Content-Type'] = "application/json;charset=utf-8";
 
//自定义实例
const service = axios.create({
	baseURL: "",				//数据请求地址
	timeout: 30 * 10000
})
 
 
//请求拦截器
service.interceptors.request.use(config => {
	//数据转换
	config.data = 'object'===typeof config.data ? JSON.stringify(config.data) : config.data;
	return config;
}, error => {
	return Promise.reject(error);
});
 
//响应拦截器
service.interceptors.response.use(response => {
	if(response.status == 200){
		return response['data'];
	}
	return Promise.reject(response);
}, error => {
	return Promise.reject(error);
});
 
export default service;