// mock/data.js

import Mock from "mockjs"
// 定义出入库类型的枚举
const inOutTypes = ["入库", "出库"]
const productNames = ["苹果iPhone 14", "三星Galaxy S22", "华为Mate 50", "小米12 Pro", "OPPO Find X5", "vivo X80", "联想小新Pro 14", "戴尔XPS 13", "惠普Spectre x360", "华硕ROG游戏笔记本", "美的智能冰箱", "海尔滚筒洗衣机", "格力空调", "西门子洗碗机", "九阳豆浆机", "飞利浦电动牙刷", "戴森吹风机", "苏泊尔电饭煲", "耐克运动鞋", "阿迪达斯跑鞋", "安踏休闲鞋", "李宁羽毛球拍", "佳能EOS R6相机", "尼康D850单反", "索尼A7 III微单", "富士X-T4", "奥林巴斯EM1 III", "蒙牛奶特牛奶", "伊利金典牛奶", "可口可乐", "百事可乐", "康师傅冰红茶", "王老吉凉茶", "好丽友派", "徐福记沙琪玛", "奥利奥饼干", "三只松鼠坚果", "良品铺子肉干", "百草味果干", "香奈儿香水", "迪奥口红", "雅诗兰黛面霜", "兰蔻小黑瓶", "SK-II神仙水", "资生堂红腰子", "美的扫地机器人", "小米空气净化器", "飞利浦智能灯泡", "小米智能插座"]
const kq = []
for (let i = 1; i <= 20; i++) {
  kq.push(`冷链${i}号仓`)
}
const productCategories = ["电子产品", "家用电器", "服装鞋帽", "美食饮品", "个护美妆", "家居家装", "母婴用品", "图书音像", "运动户外", "汽车用品", "玩具乐器", "珠宝配饰", "数码配件", "家具建材", "宠物生活", "生鲜果蔬", "粮油副食", "个人护理", "健康保健", "礼品箱包"]
const cities = ["北京", "上海", "广州", "深圳", "成都", "重庆", "杭州", "武汉", "西安", "苏州", "南京", "天津", "长沙", "郑州", "东莞", "青岛", "大连", "宁波", "合肥", "福州", "沈阳", "济南", "昆明", "石家庄", "南昌", "哈尔滨", "长春", "厦门", "无锡", "温州", "佛山", "珠海", "南宁", "贵阳", "太原", "烟台", "常州", "徐州", "泉州", "惠州"]
const warehouseTypes = ["常温库", "冷藏库", "冷冻库", "保税库", "立体库", "自动化仓库", "平面仓库", "多层货架仓库", "自动化分拣中心", "电商仓库", "生鲜仓库", "医药仓库"]
const lkNames = ["阜阳1号仓库", "临泉3号仓库", "亳州2号仓库", "界首4号仓库", "太和5号仓库", "颍上6号仓库", "芜湖7号仓库", "马鞍山8号仓库", "铜陵9号仓库", "安庆10号仓库", "黄山11号仓库", "滁州12号仓库", "宿州13号仓库", "蚌埠14号仓库", "淮南15号仓库", "淮北16号仓库", "六安17号仓库", "池州18号仓库", "宣城19号仓库", "合肥20号仓库"]
const shopNames = ["晨光文具店", "四季青服装店", "百味轩美食店", "悦读时光书店", "温馨家居馆", "潮流鞋履坊", "数码科技城", "雅致花艺店", "时尚女装阁", "乐享生活", "童趣玩具屋", "品味咖啡屋", "健康养生馆", "爱车用品店", "美美哒美妆店", "智慧书屋", "绿野仙踪花店", "潮流配饰店", "创意家居馆", "时尚男装店", "品味家居馆", "美味小厨", "星光珠宝店", "女装精品店", "动漫周边店", "户外装备店", "宝贝母婴店", "时尚精品店", "精致生活馆", "旅行背包店", "时尚女装潮店", "数码专卖店", "艺术画廊", "宠物生活馆", "书香门第书店", "时尚饰品店", "家居软装馆", "潮流女装店", "美食小铺", "生活百货超市", "手工皮具店", "男装精品店", "潮流男装店", "美妆护肤店", "文艺小书店", "复古风服装店", "汽车美容中心", "配饰集合店", "运动服饰店", "智能家居店"]
let tagList = ["已发货", "未发货", "延迟发货", "-","严重延迟发货"]

// 定义一个函数来生成随机车牌号
function generateRandomPlate() {
  // 车牌号的格式：第一位是汉字（省份简称），第二位是字母（城市代码），后面五位是数字或字母
  const provinces = ["京", "津", "沪", "渝", "冀", "豫", "云", "辽", "黑", "湘", "皖", "鲁", "新", "苏", "浙", "赣", "鄂", "桂", "甘", "晋", "蒙", "陕", "吉", "闽", "贵", "粤", "青", "藏", "川", "宁", "琼"]
  const letters = "ABCDEFGHJKLMNPQRSTUVWXYZ" // 去除容易混淆的I和O
  const chars = letters + "0123456789"
  // 随机选择一个省份简称
  const province = provinces[Math.floor(Math.random() * provinces.length)]
  // 随机选择一个字母作为城市代码
  const city = letters[Math.floor(Math.random() * letters.length)]
  // 生成后五位的随机字符，可以是字母或数字
  let suffix = ""
  for (let i = 0; i < 5; i++) {
    suffix += chars[Math.floor(Math.random() * chars.length)]
  }
  // 组合成车牌号
  const plate = `${province}${city}${suffix}`
  return plate
}
let cpList = []
for (let i = 1; i <= 80; i++) {
  cpList.push(generateRandomPlate())
}


function generateRandomNumberString(length) {
  let result = ""
  const characters = "0123456789" // 可选字符集，只包含数字
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength) // 生成一个随机索引
    result += characters[randomIndex] // 将随机字符添加到结果字符串中
  }
  return result
}
function generateOrderNumber() {
  // 使用 Mock.js 的随机字符串和数字功能
  const prefix = Mock.Random.string("upper", 2) // 生成2位大写字母前缀
  const timestamp = generateRandomNumberString(6) // 获取当前时间的时间戳
  const suffix = Mock.Random.integer(1000, 9999).toString().padStart(4, "0") // 生成4位数字后缀，并确保长度至少为4位
  // 拼接成订单编号
  return `${prefix}${timestamp}${suffix}`
}
let orderNoList = []
for (let i = 1; i <= 80; i++) {
  orderNoList.push(generateOrderNumber())
}

// 定义商品的模板
const productInfoTemplate = {
  "name|1": productNames, // 商品名称：3到6个中文字符
  sku: '@string("upper", 5, 10)', // 商品SKU：5到10个大写英文字母
  "type1|1": inOutTypes, // 出入库类型1：随机选择入库或出库
  type2: "@cword(1, 2)", // 存储类型：1到2个中文字符
  type3: "@cword(1, 2)", // 温度类型：1到2个中文字符
  "kq|1": kq,
  "productCategorie|1": productCategories,
  "num|1-100": 1, // 数量：1到100之间的整数
  "kcNum|1000-10000": 1, // 在库库存数量：1000到10000之间的整数
  date1: '@datetime("yyyy/MM/dd")', // 出入库日期1：随机日期
  date2: '@datetime("yyyy/MM/dd")', // 出入库日期2：与日期1相同或不同（这里为了简化，设置为相同）
}

const listItemTemplate = {
  "city1|1": cities, // 发货地
  "city2|1": cities, // 收货地
  "company|1": ["顺丰速运", "中通快递", "圆通速递", "韵达快递", "申通快递", "百世快递", "京东物流"], // 物流公司
  "orderCount|100-1000": 1, // 支付订单数
  "totalFee|1000-10000.2": 1, // 运费总价,保留2位小数
  "unitPrice|10-100.2": 1, // 运费单价,保留2位小数
}
Mock.mock("/api/data", "get", {
  header: ["发货地", "收货地", "物流公司", "支付订单数", "运费总价", "运费单价"],
  "list|80": [listItemTemplate], // 随机生成1到5条列表数据
})

const template2 = {
  "name|1": shopNames,
  "logisticsTime|1": "@integer(1, 48)" + "h",
  "cityTime|1": "@integer(1, 24)" + "h",
  "noShipOrder|1": "@integer(1, 999)" + "件",
  "noDeliveryOrder|1": "@integer(1, 999)" + "件",
  "progress|1": "@integer(1, 100)" + "%",
  "tag|1": tagList,
}
Mock.mock("/api/data2", "get", {
  header: ["商户名称", "物流发货时效", "同城配送时效", "48h未发货订单", "48h未配送订单", "发货进度", "标签"],
  "list|80": [template2],
})

const template3 = {
  "rank|+1": 1,
  "city|1": cities,
  "count|1-9999": 1,
  "count1|1-9999": 1,
  "count2|1-9999": 1,
}
Mock.mock("/api/data3", "get", {
  header: ["排名", "城市", "数量"],
  "list|10": [template3],
})  

const template4 = {
  "orderNo|1": orderNoList,
  "logisticsNo|1": orderNoList,
  "name|1": productNames,
  "unit|1": "件",
  "num|1-9999": 1,
  "address|1": cities,
  "status|1": tagList,
}
Mock.mock("/api/data4", "get", {
  header: ["订单号", "物流单号", "商品名称", "商品单位", "商品数量", "收货地址", "状态"],
  "list|80": [template4],
})


const listItemTemplate5 = {
  "warehouseType|1": warehouseTypes,
  "lkName|1": lkNames,
  "lkAllNum|50000-999999": 1, // 冷库总面积：50000到99999之间的整数
  "lkNum|10000-99999": 1, // 冷库剩余容积：1000到50000之间的整数
}
Mock.mock("/api/data5", "get", {
  header: ["库区类型", "冷库名称", "冷库总面积", "冷库剩余容积"],
  "list|80": [listItemTemplate5], // 随机生成1到5条列表数据
})

const listItemTemplate6 = {
  "name|1": productNames,
  "type|1": productCategories,
  "num|1-5000": 1,
  "date|1": "@datetime('yyyy/MM/dd')",
}
Mock.mock("/api/data6", "get", {
  header: ["商品名称", "商品品类", "在库库存量Kg", "日期"],
  "list|80": [listItemTemplate6], // 随机生成1到5条列表数据
})

const listItemTemplate7 = {
  "name|1": productNames,
  "type|1": inOutTypes,
  "num|1-5000": 1,
  "date|1": "@datetime('yyyy/MM/dd')",
}
Mock.mock("/api/data7", "get", {
  header: ["商品名称", "出入库类型", "数量", "出入库日期"],
  "list|80": [listItemTemplate7], // 随机生成1到5条列表数据
})
