import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dataV from '@jiaminghi/data-view';
import mocks from "../src/mocks/index";


// 引入全局css
import './assets/scss/style.scss';
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';

import VueSeamlessScroll from "vue-seamless-scroll"
Vue.component('VueSeamlessScroll', VueSeamlessScroll);

import countTo from "vue-count-to"
Vue.component('countTo', countTo);




import service from "@/utils/request.js";
Vue.prototype.$api = service



//引入echart
//4.x 引用方式
// import echarts from 'echarts'
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

// 全局注册
Vue.component('icon', Icon);
Vue.use(dataV);

// 按需引入组件
import { Button, Select,DatePicker,Input,Steps,Step,Form,FormItem,Dropdown,DropdownMenu,DropdownItem } from 'element-ui'
// 注册组件
Vue.use(Button)
Vue.use(Select)
Vue.use(DatePicker)
Vue.use(Input)
Vue.use(Steps)
Vue.use(Step) 
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)


new Vue({
  router,
  store,
  mocks,
  render: h => h(App)
}).$mount('#app')
