import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/",
    redirect: '/home',
    name: "index",
    component: () => import("@/views/index.vue"),
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home/index.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: "logistics",
        name: "test1",
        component: () => import("@/views/logistics/index.vue"),
      },
      {
        path: "cold-chain",
        name: "cold-chain",
        component: () => import("@/views/cold-chain/index.vue"),
      },

    ],
  },
]
const router = new VueRouter({
  routes,
})

// 路由守卫
router.beforeEach((to, from, next) => {
  const isLogin = localStorage.getItem('isLogin')
  
  if (to.path === '/login') {
    // 如果是登录页面，直接通过
    next()
  } else {
    // 非登录页面，检查是否已登录
    if (isLogin === 'true') {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
